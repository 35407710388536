.Field {
  margin-bottom: 12px;
}
.Field input {
  width: 100%;
}
.Field label {
  font-weight: 500;
}
.FormLogin {
  margin-top: 10vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 23vw;
}
.Title {
  text-align: left;
  margin-top: 60px;
  font-weight: 700;
  line-height: 28px;
}
.SubTitle {
  font-size: 18px;
  line-height: 32px;
  color: #818181;
  margin-bottom: 40px;
}
.Button {
  margin: 28.5px 0;
  width: 100%;
}

.ForgetLink {
  float: right;
  font-size: 12px;
  color: #9b3789;
}
.ForgetLink:hover {
  text-decoration: none;
  color: #3b0532;
}

@media only screen and (max-width: 1024px) {
  .FormLogin {
    width: 400px;
  }
}
.Field {
  margin-top: 40px;
}
.CheckBox {
  margin-top: 60px;
}
