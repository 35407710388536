.HeaderItem {
  height: 40px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  align-content: space-between;
}
.Info,
.InfoOn {
  font-size: 12px;
}
.InfoNone {
  font-size: 12px;
  color: white;
}
.InfoDate,
.InfoAdmin {
  font-weight: 700;
  font-size: 12px;
}
.InfoAdmin,
.InfoOn {
  margin-left: 4px;
}
.MappingHeader {
  position: stcky;
  top: 0; /* Don't forget this, required for the stickiness */
  right: 0;
  left: 0;
  min-height: 80px;
  display: flex;
  border-radius: 16px 16px 0px 0px;
  padding: 22.5px 12px;
  background: white;
  z-index: 3;
}
.CheckBox {
  width: 240px;
  display: flex;
  align-items: center;
  margin: 0 12px;
}

.HeaderRight {
  display: flex;
  width: calc(100% - 228px);
}

.NewData {
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  margin: 0 12px;
}

.Existing {
  width: 50%;
  background: white;
  margin: 0 12px;
}
.ButtonSearch {
  
  margin-left: 8px;
}
.ButtonGroup{
  margin-top: 6px;
  display: flex;
  align-items: center;
}
.ButtonReject{
  margin-top: 6px;}
@media (max-width: 1350px) {
  .MappingHeader {
    display: block;
  }
  .CheckBox,
  .NewData,
  .Existing {
    width: 100%;
  }
  .NewData,
  .Existing {
    padding: 0 24px;
  }
  .HeaderRight {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
}

@media (max-width: 800px) {
  .HeaderItem,
  .NewData,
  .Existing ,.ButtonGroup{
    display: block;
    flex-flow: unset;
  }
  .NewData h6,
  .Existing h6,
  .ButtonReject,
  .ButtonGroup,
  .ButtonGroup button {
    width: 100%;
  }
  .ButtonGroup button {
    margin: 4px 0;
  }
  .ButtonSearch {
    margin-left: 0px;
  }
  .ButtonGroup {
    margin-top: 40px;
  }
  .Existing {
    margin-top: 40px;
  }
  .MappingHeader {
    height: 320px;
  }
}
