.ModalBody {
    min-width: 520px !important;
    width: 20vw !important;
    max-width: 1600px !important;
}

.ModalTitle {
    font-weight: bold;
}

.ModalContent {
    color: #666666;
}

.TableList td {
    font-size: 16px !important;
}