.Container {
  display: flex;
  margin-top: 40px;
  padding-bottom: 10px;
  margin-bottom: 40px;
}
.CardUnMapped {
  margin-right: 40px;
  width: 270px;
  max-height: 200px;

}
.CardMapped{
margin-left: 40px;
width: 270px;
max-height: 200px;
}
/* .CardIcon{
  padding: 0 26.67px 0 4px ;
}
.CardMapped{
  word-wrap: break-word;
  width: 248px;
  height: 500px;
  background: white;
  margin-right: 40px;
  border-radius: 20px;
  box-shadow: 5px 5px #9b379b;
}
.CardMapped p{
  color: #999999;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
}
.CardUnmapped{
  word-wrap: break-word;
  width: 248px;
  background: white;
  margin-right: 40px;
  border-radius: 20px;
  box-shadow: 5px 5px #9b379b;
}
.CardUnmapped {
  color: #F50000;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
}
h3{
  font-weight: 700;
} */

@media (max-width: 500px) {
  .Container {
    display: block;}
  .CardUnMapped {
    margin-right: 0px;
    width:  100%;
    max-height: 200px;
    margin-bottom: 40px;
  
  }
  .CardMapped{
  margin-left: 0px;
  width: 100%;
  max-height: 200px;
  }
}
