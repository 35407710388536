.Navbar {
  width: 300px;
  position: fixed;
  right: 0;
  top: 0;
}

.DropdownMenu {
  position: absolute;
  z-index: 9;
  display: none;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 18px;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border-radius: 0.25rem;
}

.DropdownItem {
  display: block;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  border: 0;
}
.Dropdown {
  width: 216px;
  position: fixed;
  top: 0;
  right: 0;
  padding: 11px 12px;
}

.DropdownButton {
  display: flex;
  margin: 9px 0;
  padding: 6px 0;
  white-space: normal;
}
.DropdownItem {
  display: flex;
  align-items: center;
  margin: 12.5px 0;
  padding: 0;
  font-size: 25px !important;
  white-space: normal;
}
.DropdownItemLogout {
  display: flex;
  align-items: center;
  margin: 12.5px 0;
  padding: 0;
  font-size: 25px !important;
  white-space: normal;
}

.Icon {
  margin-right: 10px;
  margin-left: 10px;
}
.Item {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}
@media (max-width: 992px) {
  .DropdownButton {
    float: right;
    width: 45px;
  }
  .HideItem {
    display: none;
  }
}
.TooltipBox {
  position: relative;
  display: inline-block;
}

.TooltipBox .TooltipText {
  visibility: hidden;
  background-color: #9b3789;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0 8px;
  right: 170px;
  top: 5px;
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  z-index: 99;
  white-space: nowrap;
}
.TooltipBox:hover .TooltipText {
  visibility: visible;
}
