.CheckBox {
  display: flex;
  align-items: center;
  margin: 0 12px;
  margin-bottom: 8px;
}
.Select{
  font-size: 18px !important;
    line-height: 19px !important;

}
.InputArea {
  display: flex;
  width: calc(100% - 240px);
}
.Label {
  width: 224px;
  margin: 0 12px 0 12px;
  padding-left: 16px;
}
.SubLabel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
}

.NewData {
  width: 50%;
  display: flex;
  margin: 8px 12px;
}
.Existing {
  width: 50%;
  margin: 8px 12px;
}
.InputMultiComparison {
  margin: 16px 12px;
  width: calc(100% - 12px);
}
.InputMultiComparisonItem {
  margin: 1px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.InputMultiComparisonItemDiff {
  margin: 1px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  background: #ffe99b;
}

.InputMultiComparisonInput {
  width: 100%;
}
.InputMultiComparisonInputSelect {
  width: 100%;
}
.InputComparisonInput {
  width: 100%;
}
.Space {
  width: 24px;
}
.Col {
  width: calc(50% - 12px);
  padding: 0px !important;
}
@media (max-width: 1350px) {
  .InputMultiComparisonItem ,.InputMultiComparisonItemDiff{
    display: block;
    padding: 8px 0;
  }
  .InputArea{
    display: block;
    padding-left: 16px;
    width: calc(100% - 24px);
  }
  .NewData,.Existing{
    width: 100%;
  }
}
