.FormUl{
  width: 100% ;
  padding: 0 !important;
  margin-bottom: 0 !important;
}
.FormLi{
  list-style: none;
  display: flex;
  padding-bottom: 8px;
}
.Button{
  width: 100%;
}
.Box{
  /* width: calc(100% - 264px); */
  display: block;
}
.InputKey{
  width: calc(100% ) !important;
  margin-right: 4px;
}
.InputValue{
  width: calc(100% ) !important;
  margin-left: 4px;
  /* margin-right: 8px; */


}
.Disable{
  cursor: not-allowed;
}
.ButtonAddIcon{
  margin-right: 8px;
}