.Form {
    padding: 0 0 20px 0;
}

.FormRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.FormColLeft {
    width: 100%;
}

.FormColRight {
    display: flex;
    align-items: center;
    width: 18%;
    justify-content:flex-start;
}

.FormColRight button {
    margin: 0 10px;
}

.Col {
    padding: 0 10px !important;
}

.FormInput {
    margin: 5px 0px;
    width: 100%;
}

.ButtonContainer {
    margin-left: 10px !important;
}

.BoxInputSearch {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #D5D6D8;
}

.RightButtonContainer {
    margin-left: auto; 
    margin-right: 0;
}

.IconSearch svg {
    margin-right: 8px;
    height: 12px;
    width: 12px;
}

.IconSearch {
    height: 10%;
}

.InputSearch {
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 8px;
}

.InputSearch:focus {
    border: none;
}

@media (max-width: 1600px) {
    .FormColLeft,

    .FormColRight {
        width: 100%;
        padding: 5px 10px;
    }

    .FormColRight {
        justify-content:flex-end;
    }
}

@media (max-width: 700px) {
    .FormColRight {
        flex-wrap: wrap;
        float: right;
    }

    .FormColRight button {
        width: 100%;
    }
}