.Container {
  margin-bottom: 40px;
}
.Row {
  display: flex;
  flex-flow: column wrap;
  align-content: flex-end;
  margin-bottom: 40px;
}

@media (max-width: 440px) {
 
  .Container div button {
    width: 100%;
    margin: 8px 0;
  }
}
