.Title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
}
.Value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 16px;
  word-wrap: break-word;

}
.Box{
  width: 100%;
}