
.Field{
  margin-bottom: 20px;
}
.Col{
  padding: 0px !important;
  margin-bottom: 20px;
}
.InputLeft{
  width:calc(100% - 12px)!important;
  margin-right: 12px;
}
.InputRight{
  width:calc(100% - 12px)!important;
  margin-left: 12px;

}
.Input{
  width:calc(100% - 12px);

}
@media (max-width: 992px){
  .InputLeft{
    width:100%;
    margin-right: 0px;
  }
  .InputRight{
    width:100%;
    margin-left: 0px;
  
  }
}