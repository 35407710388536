.Spinner{
 
  align-items: center;
  animation:animatetop 0.5s;
  
}@keyframes animatetop{from{display:none;opacity:0} to{display:contents;opacity:1}}

.Spinner p{
  vertical-align: middle;
  text-align: center;
  margin: 0 auto;
  font-size: 12px;
  padding-top: 10px;
}