.Container {
  background: #f9f9f9;
  max-height: 2000px;
  border-radius: 0px 0px 10px 10px;
  padding: 8px 16px 8px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 5;
}
.SelectReason {
  padding: 0px !important;
}
.Row {
  width: 100% !important;
  padding: 0 !important;
}
.Col {
  padding: 0 8px !important;
}
.Reason {
  width: 100% !important;
  height: 49px !important;
}
.Title {
  width: 179px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
@media (max-width: 1000px) {
  .Title {
    width: 100%;
    margin: 4px;
  }
  .Container {
    display: block;
    padding: 8px 24px;
  }
  .Col {
    padding: 8px 4px !important;
  }
}
