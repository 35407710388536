.CheckBox {
  width: 240px;
  display: flex;
  align-items: center;
  margin: 0 12px;
}

.InputArea {
  width: calc(100% - 240px);
  display: flex;
}
.Label {
  font-size: 18px;
  margin: 0 20px 0 8px;
  color: #666666;
}

.NewData {
  width: 50%;
  /* min-width: 315px; */
  display: flex;
  margin: 0 12px;
}

.Existing {
  width: 50%;
  /* min-width: 315px; */
  margin: 0 12px;
}
.InputComparison {
  width: 100%;
  padding: 30px 12px;
  display: flex;
  align-items: center;
}
.InputComparisonDiff {
  width: calc(100% - 24px);
  padding: 30px 0;
  margin: 1px 12px;
  display: flex;
  border-radius: 6px;
  align-items: center;
  background: #ffe99b;
}
.InputComparisonInput {
  width: 100% !important;
}
.InputComparisonInput[type="date"] {
  padding: 8px 14px 12px;
}
.InputComparisonDate {
  width: 200% !important;
}
.Switch {
  margin-top: -25px;
  margin-bottom: 0px;
}
.Calender {
  width: 292px;
}
.Input{
  width: 100%;
  display: block;
  position: relative;
}
.Message{
  width: 100%;
  position: absolute;
  font-size: 10px;
  color: red;
}

@media (max-width: 1350px) {
  .InputComparison,
  .InputArea,
  .InputComparisonDiff {
    display: block;
    width: calc(100% - 24px);
    padding: 8px 12px;
  }
  .InputComparison,
  .InputComparisonDiff {
    width: 100%;
  }
  .InputComparisonDiff {
    width: calc(100% - 24px);
    padding: 8px 0;
  }
  .CheckBox {
    width: 100%;
    margin: 8px 12px;
  }
  .NewData,
  .Existing {
    width: 100%;
    margin: 8px 24px;
  }

  .InputArea {
    width: calc(100% - 24px);
  }.Input{
    margin-bottom: 30px;
  }
  
}
