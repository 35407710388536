.ContainerAutocomplete {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}
.Input {
  width: 100%;
  border: 1px solid #999;
  padding: 8px;
}
.BoxOption {
  z-index: 999;
}
.BoxOption,
.IconSearch,
.IconSearch svg {
  background-color: rgba(249, 249, 249, 1);
}
.IconSearch svg {
  margin-right: 8px;
  height: 12px;
  width: 12px;
}
.IconSearch {
  height: 10%;
}
.BoxInputSearch {
  display: flex;
  margin-top: 8px;
  align-items: center;
}
.InputSearch {
  width: 100%;
  border: none;
  border-radius: 0;
  background-color: rgba(249, 249, 249, 1);
  padding: 8px;
}
.InputSearch:focus {
  border: none;
}
.NoAutocomplete {
  z-index: 3;
  position: absolute;
  align-items: center;
  background-color: rgba(249, 249, 249, 1);
  list-style: none;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
  overflow-y: auto;
  padding: 0px;
}
.Autocomplete {
  position: absolute;
  background-color: rgba(249, 249, 249, 1);
  list-style: none;
  color: #666666;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding-left: 0;
  z-index: 999;
  margin-bottom: -100px;
}
.ListOption {
  list-style: none;
  max-height: 175px;
  overflow-y: auto;
  padding: 0px;
  margin-bottom: 0px;
}
.Autocomplete li {
  padding: 8px;
}
.ListOption::-webkit-scrollbar {
  width: 4px;
  /* margin: 10px 0; */
}

.Autocomplete > .active,
.Autocomplete li:hover {
  background-color: #9b3789;
  color: white;
  cursor: pointer;
}
.Button {
  display: flex;
  align-items: center;
  padding-right: 7px;
  margin-left: 0px !important;
  background-color: #f9f9f9;
  border-radius: 0px 4px 4px 0px;
}
.HideList {
  display: none;
}
.IconSelect {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 20px);
}
