.Logo {
  display: flex;
  position: fixed;
  padding: 0px 5px;
}

.Toggle {
  display: none;
}
@media (max-width: 1350px) {
  .Toggle {
    display: block;
    margin-right: 24px;
  }
  .Toggle:hover {
    cursor: pointer;
  }
  .Logo {
    padding: 0;
  }
}
