.CardIcon {
  padding: 0 14.67px 0px 26.67px;
}
.RowCount {
  margin-top: 15.33px;
}
.Card {
  word-wrap: break-word;
  background: white;
  border-radius: 20px;
  box-shadow: 8px 8px #9b379b;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.Card:hover{
  cursor: pointer;
  box-shadow: 10px 10px #9b379b;
}
.CardTitleUnMapping {
  color: #f50000;
  padding-left: 5px;
  padding-right: 5px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 22px;
}
.CardTitleMapping {
  padding-left: 5px;
  padding-right: 5px;
  color: #9b3789;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 22px;
}
.CardTitle {
  
  color: #999999;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 22px;
  margin-bottom: 0;
}

.CardSubtitle {
  color: #9b3789;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 22px;
  padding-left: 24px;
  margin-bottom: 8px;
}

.CardFooter {
  padding: 10px 20px 24px 20px;
  font-size: 14px;
  color: #666666;
  margin-top: -5px;
}
.RowTitle {
  padding: 24px 20px 0px 20px;
}
.Card h6 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
}
