.Title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
}
.Toggle {
  position: relative;
  font-size: 14px;
  margin-top: 5px;
  color: #9b3789;
  font-weight: 500;
  display: flex;
}
.ToggleButton {
  font-size: 14px;
  color: #9b3789;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.Toggle p {
  margin-right: 4px;
}
.Container img {
  margin-top: -5px;
}
.Header{
  display: flex;
  align-items: center;
}
.ButtonHeader{
  width: calc(100% - 90px);
  display: flex;
  flex-flow: column wrap;
  align-content: flex-end;
  margin-right: 0;
}
.DropDown {
  margin-top: 40px;
  border-radius: 8px;
  position: absolute;
  width: 728px;
  min-height: 174px;
  color: #222222;
  padding: 24px;
  background: white;
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.15));
  z-index: 5;
}
.SelectTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 12px;
}
.SelectItem {
  margin: 12px 0px;
  display: flex;
}

.SelectItem img {
  margin-left: 14px;
}
.AutoComplete {
  width: 100%;
  margin-right: 16px;
}
.AutoCompleteBox {
  display: flex;
}
.AutoCompleteIcon {
  position: relative;
  right: 40px;
  background: #f9f9f9;
}
.ButtonGroup {
  margin-top: 16px;
  float: right;
}
.ButtonLeft {
  margin-right: 8px;
}
.ButtonGroupItem {
  width: 95px;
  display: flex;
  flex-flow: column wrap;
  align-content: flex-end;
}
.Row {
  width: calc(100% - 95px);
  height: 12px;
  display: flex;
}
.ColLeft {
  width: 50%; height: 12px;
  margin-right: 12px;
}
.ColRight {
 height: 12px;
  width: 50%;
  margin-right: 12px;
}
.ColRight input{
  width: 100%;
}
/* .ButtonGroup button {
  border-radius: 4px;
  margin-left: 16px;
  font-size: 12px;
} */
/* .ButtonLeft {
  background: rgba(255, 255, 255, 0);
  color: #9b3789;
  border: 2px solid #9b3789;
  margin-left: 16px;
}
.ButtonRight {
  background: #9b3789;
  color: white;
  border: 2px solid #9b3789;
}
.ButtonRight:hover,
.ButtonLeft:hover {
  background: #9b3789b9;
  color: white ;
  border:2px solid #b37aa8;
}
.ButtonDisable {
  background: #cccccc;
  border: 2px solid #cccccc;
  color: white;
} */
