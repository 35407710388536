.Title {
  margin-top: 8px;
  margin-bottom: 40px;
}
.BoxTitle {
  display: flex;
  justify-content: space-between;
}

.BoxTitle button {
  height: 52px;
}
