.MappingFrom {
  margin-top: 16px;
  width: 100%;
  border-radius: 10px;
  background: white;
  height: 598px;
  filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.15));
  scroll-padding: 50px;
  overflow-y: hidden;
  overflow-x: hidden;
}
.MappingBody {
  height: calc(100% - 100px);
  overflow-y: auto !important;
  overflow-x: hidden;
}
.Load {
  width: 100%;
  height: 100%;
}
.Load div{
  margin: 20% auto 0 auto;
}
@media (max-width: 1350px) {
  .MappingBody {
    height: calc(100% - 200px);
  }
}
@media (max-width: 800px) {
  .MappingBody {
    height: calc(100% - 320px);
  }
}
.Form {
  height: 590px;
  overflow-y: auto;
}
