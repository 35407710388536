.Header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  color: #1d1d1d;
  padding: 32px 0 8px 0;
}
.Body {
  width: 310px;
  min-width: 310px;
  padding: 8px 42px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #666666;
}
.Footer {
  padding: 8px 28px 32px 28px;
}
.FooterButton{
  margin: 0 12px;
}
