.Title {
  margin-top: 8px;
  margin-bottom: 48.5px;
}

.Container {
  padding: 24px;
  border: 1px solid #dadcde;
  border-radius: 10px;
  box-shadow: 2px 10px 10px 0px #00000026;
}

.TabChoose {
  display: flex;
}

.TabChooseItem {
  cursor: pointer;
  padding: 16px;
  font-size: 24;
}

.TabChooseItemActive {
  cursor: pointer;
  padding: 16px;
  border-bottom: 2px solid #9b3789;
}

.TabChooseItem h6,
.TabChooseItemActive h6 {
  color: #9b3789;
  font-size: 24px;
  font-weight: 600;
}
