.FormUl {
  width: 100%;
  padding: 0 !important;
  margin-bottom: 0 !important;
}
.FormLi {
  list-style: none;
  display: flex;
  padding-bottom: 8px;
}
.Button {
  margin-top: 2px;
  width: 100%;
  height: 44px !important;
}
.Box {
  width: 100%;
  display: block;
}
.InputKey {
  width: calc(100% - 24px) !important;
  margin-right: 4px;
}
.InputValue {
  width: calc(100% - 24px) !important;
  margin-left: 4px;
  margin-right: 8px;
}
.InputUnsupport {
  width: 100%;
}
.Disable {
  cursor: not-allowed;
}
.ButtonAddIcon {
  margin-right: 8px;
}
