.Header {
  display: flex;
}
.HiderSide {
  align-items: center;
  padding: 0px !important;
  display: flex;
  flex-flow: column wrap;
  align-content: flex-end;
}
.Title {
  margin-right: 24px;
}
.HeaderSideLeft {
  display: flex;
  align-items: center;
  padding: 0 !important;
}
@media (max-width: 1400px) {
  .HeaderSideLeft {
    display: block;
  }
}
