.Img {
  display: block;
  margin: 80px auto 40px auto;
}
.BoxMessage {
  text-align: center;
  width: 100%;
  margin-bottom: 90px;
}
.TitleMessage {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #1d1d1d;
}
.DetailMessage {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #424242;
}
.DetailMessageGroup{
  margin: 24px 0;
}