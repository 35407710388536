.Title{
  font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 33px;
/* identical to box height */


color: #222222;
}