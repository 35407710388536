.SlcInput {
  display: flex;
  align-items: center;
  align-content: center;
  background: #ffffff;
  border: 1px solid #cacccf;
  border-radius: 6px;
  width: 100%;
  min-height: 33px;
  padding: 12px 16px;
}
.SlcContainer{
  position: relative;
}

.SlcInput {
  display: flex;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
}
.SlcInput svg {
  float: inline-end;
  width: 24px;
}
.SlcInput Span {
  width: calc(100% - 24px);
}
.SlcInput[data-placeholder]:not([data-placeholder=""]):empty::before {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: rgba(66, 66, 66, 0.3);
  content: attr(data-placeholder);
}
.SlcInputs[data-placeholder]:not([data-placeholder=""]):empty::before {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: rgba(66, 66, 66, 0.3);
  content: attr(data-placeholder);
}
.SlcInputs{
  width: calc(100% - 24px);
}
.SlcOption {
  margin: 8px 0;
  position: absolute;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: block;
  z-index: 999;
}
.SlcOptionDown {
  overflow-y: auto;
  z-index: 9999;
  margin-top: 10px;
  clip-path: inset(0px -10px -10px -10px);
  transition: border-bottom-left-radius 0.5s ease-in, border-bottom-right-radius 0.5s ease-in;
}
.SlcOptionUp {
  z-index: 9999;
  bottom: 60px;
  clip-path: inset(-10px -10px 0 -10px);
  transition: border-top-left-radius 700ms, border-top-right-radius 700ms;
}
.SlcOptionUl {
  list-style: none;
  margin: 0;
  padding: 0;
}
.SlcOptionLi {
  display: block;
  padding: 0;
  margin: 0;
  padding: 12px 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #333333;
}
.SlcOptionLi:hover {
 
  border-radius: 6px;
}
.Input{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Input svg {
  position: absolute;
  margin-right: 14px;
}