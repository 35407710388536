.Container {
  border-radius: 10px 10px 0 0 ;
  padding: 10px 14px;
  background-color: #f9f9f9;
}
.FormRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.FormColLeft {
  width: 82%;
  /* background-color: red; */
}
.FormColRight {
  display: flex;
  align-items: center;
  width: 18%;
  justify-content:flex-start;
  /* background-color: blue; */
}
.FormColRight button{
margin: 0 10px;

}
.Col{
  padding: 0 10px !important;
}
/* .Form {
  width: 81%;
} */
.FormInput {
  margin: 5px 0px;
  width: 100%;
}
@media (max-width: 1600px) {
  .FormColLeft,
  .FormColRight {
    width: 100%;
    padding: 5px 10px;
  }
  .FormColRight {
    justify-content:flex-end;
  }
}


@media (max-width: 700px) {
  .FormColRight{
    /* display: inline-block; */
    flex-wrap: wrap;
    float: right;
  }
  .FormColRight button{
   
    width: 100%;
    /* align-items: center; */
    /* text-align: center; */
    /* padding: 5px auto !important; */
  
  }
}