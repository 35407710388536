.Switch {
    margin-top: -25px;
    margin-bottom: 20px;
}

.ButtonContainer {
    width: 20%;
    margin-left: auto;
    margin-right: 0;
    align-content: flex-end;
    display: flex;
}

.FormCreateNotification {
    margin-top: -20px !important;
    margin-left: -20px !important;
}

.ContainerForm {
    margin-bottom: 20px;
}

.FormInput {
    margin-top: -15px !important;
}

.Select {
    margin-top: 10px;
    width: 28.5%;
}

.SaveButton {
    margin-left: 15px;
    width: 120px;
}

.CancelButton {
    width: 120px;
}

.RemoveButton {
    width: 10px;
    height: 10px;
    margin-top: 25px;
    background-color: transparent;
    border: none;
}

.RemoveButton:hover {
    background-color: transparent;
    text-decoration: none;
}

.AddButton {
    width: 10px;
    height: 10px;
    margin-top: 25px;
    background-color: transparent;
    border: none;
}

.AddButton:hover {
    background-color: transparent;
    text-decoration: none;
}

.ButtonRow {
    width: 100%;
    display: block;
    margin-top: -10px !important;
}

.ListNotificationContainer {
    display: flex;
    align-items: center;
}

.ListNotificationSelect {
    outline: none;
    height: 45px;
    margin-top: 24px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ListNotificationInput {
    width: 100%;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.MandatoryFlag {
    color: red;
}

.PhoneListColumn {
    display: flex;
}

.PhoneListColumnChild {
    display: flex;
    flex-direction: column;
}

.PhoneListColumnChildRight {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}