.ContentLeft {
  height: 100vh !important;
  position: absolute;
  width: 58vw !important;
}

.ContentRight {
  overflow: auto !important;
  position: absolute;
  height: 100vh !important;
  right: 0;
  width: 42vw !important;
  background: white;
}

.Footer {
  position: absolute;
  right:  40px;
  top: 94%;
  bottom: 4.44%;
}
.Modal{
  backdrop-filter: blur(1px);
  background: rgba(255, 255, 255, 0.441) !important;
}
.Header,
.Body,
.Footer{
  background: transparent !important;
}
@media only screen and (max-width: 1024px) {
  .ContentLeft {
    display: none !important;
  }

  .ContentRight {
    width: 100vw !important;
    background: white;
  }
}
