.Modal{
    background: #ffffff56;
    }
    .Header {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 33px;
      text-align: center;
      color: #1d1d1d;
      padding: 32px 0 8px 0;
    }
    .Body {
      min-width: 290px;
      width:100%;
      max-width: 438px;
      padding: 8px 42px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #666666;
    }
    .Footer {
      width: 100%;
      padding: 8px 28px 32px 28px;
    }
    .FooterButton{
      display: block;
      margin: 0 auto;
    }
    .Title{
      margin-top: 16px;
    }
    .FailButton{
      background: #CB1801;
      color: white;
      border-color:#CB1801 !important ;
    }
    .FailButton:hover{
      background: #e71f05f9;
      color: white;
      border-color:#e71f05f9 !important ;
    }
    
    .SuccessButton{
      background: #54B91B;
      color: white;
      border-color:#54B91B !important ;
    }
    .SuccessButton:hover{
      background: #5ebd27;
      color: white;
      border-color:#5ebd27 !important ;
    }