.PageTitle {
  display: flex;
  margin-bottom: 40px;
  margin-top: 8px;
}
.Title {
  width: 50%;
}
.CountMappingData {
  width: 50%;
  display: flex;
  flex-flow: column wrap;
  align-content: flex-end;
}
.PartOf {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  text-align: right;
  color: #1d1d1d;
}
.Sum {
  margin-left: 8px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  text-align: right;
  color: #818181;
  width: 100px;
  margin: 10px;
}
@media (max-width: 700px) {
  .PageTitle {
    display: block;
  }
  .Title,
  .CountMappingData {
    width: 100%;
  }
  .CountMappingData {
    display: block;
    flex-flow: unset;
  }
}
