@charset "UTF-8";
:root {
  --border-success: rgba(84, 185, 27, 0.25);
  --border-danger: rgba(203, 24, 1, 0.25);
  --border-info: #46a4fc57;
  --border-size: 2px;
  --border-type: solid;
  --border-warning: rgba(248, 172, 48, 0.25);
  --color-black: black;
  --color-black-02: rgba(0, 0, 0, 0.2);
  --color-black-05: rgba(0, 0, 0, 0.5);
  --color-clear: #fff;
  --color-clear-08: rgba(255, 255, 255, 0.822);
  --color-danger: #ff0000;
  --color-dropdown: #212529;
  --color-gray-0144: rgba(66, 66, 66, 0.144);
  --color-gray-03: rgba(66, 66, 66, 0.3);
  --color-gray-1a1a1a: #1a1a1a;
  --color-gray-1d11d: #1d1d1d;
  --color-gray-212529: #212529;
  --color-gray-222222: #222222;
  --color-gray-333333: #333333;
  --color-gray-424242: #424242;
  --color-gray-666666: #666666;
  --color-gray-818181: #818181;
  --color-gray-999999: #999999;
  --color-gray-ACACAC: #acacac;
  --color-gray-c4c4c4: #c4c4c4;
  --color-gray-cacccf: #cacccf;
  --color-gray-D7D7D7: #d7d7d7;
  --color-gray-eeeeee: #eeeeee;
  --color-gray-f8f9fa: #f8f9fa;
  --color-info: #0dcaf0;
  --color-primary: #9b3789;
  --color-primary-025: rgba(155, 55, 137, 0.25);
  --color-primary-e8: #9b3789e8;
  --color-primary-hover: rgba(155, 55, 137, 0.8);
  --color-primary-light: #f5ebf3;
  --color-primary-light-f9: #f9f9f9;
  --color-success: #57c22d;
  --color-transparent: transparent;
  --color-warning: #ffc107;
  --filer-shadow-fixColumn: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
  --filter-shadow: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.15));
  --font-family-content: "Roboto", sans-serif;
  --font-family-primary: "Open Sans";
  --icon-arrowdown: url("./Assets/Icons/ArrowDown.svg");
  --w-100: 100%;
}
@import url("https://fonts.googleapis.com/css2?family=Manjari:wght@100;400;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
input:disabled {
  color: #444242a1;
}
input[type="checkbox"]:disabled:hover {
  cursor: not-allowed !important;
  border: none !important;
}

/* Component Top Bar */
.top-bar {
  background: var(--color-clear);
  box-shadow: 0px 4px 12px var(--color-primary-025);
}
/* Component SideBar */
.subsubmenuLink {
  width: var(--w-100);
}
.subsubmenu-title {
  color: var(--color-gray-424242);
}
.subsubmenu-box-title,
.subsubmenu-box-title-active {
  margin: 4px 0 4px 24px;
  padding: 5px 8px;
}
.subsubmenu-box-title:hover .subsubmenu-title,
.subsubmenu-box-title-active .subsubmenu-title {
  color: var(--color-primary);
}
.subsubmenu-box-title-active {
  background: var(--color-primary-025);
  border-radius: 12px;
}
/* Component Multi Select */
.select-input-active {
  border: 1px solid var(--color-primary) !important;
}
.select-option-li:hover {
  color: var(--color-clear);
  background: var(--color-primary);
}
.multi-select-option-li:hover {
  color: var(--color-gray-333333);
  background: var(--color-primary-025);
}
/* Component Modal */
.modal-dialogs {
  background-color: var(--color-black-05);
}
.modal-contents {
  background-color: var(--color-clear);
}
/* Component Button */
button:disabled {
  cursor: not-allowed;
}
.button-tertiary {
  font-family: var(--font-family-primary);
  background: var(--color-transparent);
  border-color: var(--color-transparent) !important;
  color: var(--color-primary);
}
.button-tertiary:hover {
  color: var(--color-primary-hover);
}
.button-tertiary:disabled {
  font-family: var(--font-family-primary);
  background: var(--color-transparent);
  border-color: var(--color-transparent) !important;
  color: var(--color-gray-ACACAC);
}
.button-secondary {
  font-family: var(--font-family-primary);
  background: var(--color-transparent);
  border-color: var(--color-primary) !important;
  color: var(--color-primary);
}
.button-secondary:hover {
  color: var(--color-primary-hover);
  border-color: var(--color-primary-hover) !important;
}
.button-secondary:disabled {
  font-family: var(--font-family-primary);
  background: var(--color-transparent);
  border-color: var(--color-gray-D7D7D7) !important;
  color: var(--color-gray-ACACAC);
}
.button-primary {
  font-family: var(--font-family-primary);
  background: var(--color-primary);
  border-color: var(--color-primary) !important;
  color: var(--color-clear);
}
.button-primary:hover {
  color: var(--color-clear);
  background: var(--color-primary-hover);
  border: 2px solid transparent !important;
}
.button-primary:disabled {
  font-family: var(--font-family-primary);
  background: var(--color-gray-D7D7D7);
  border-color: var(--color-gray-D7D7D7) !important;
  color: var(--color-gray-ACACAC);
}
/* Component Sidebar Icon */
.overlay-styale-tooltip div.ant-popover-inner {
  background: var(--color-primary);
  border-radius: 8px;
  margin-left: 8px;
  box-shadow: none;
  box-shadow: none;
}
.overlay-styale-tooltip div.ant-popover-inner-content {
  font-family: var(--font-family-content);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  background: var(--color-primary);
  padding: 4px;
  border-radius: 8px;
  color: var(--color-clear);
}
div.ant-popover-arrow {
  display: none;
}

/* Component Table Limit */
.select-box {
  border: 1px solid var(--color-primary);
  width: 55px;
  background: var(--color-clear);
}
.select-box select {
  font-family: var(--font-family-primary);
  width: 55px;
  background: var(--color-clear);
}
.select-box:after {
  content: var(--icon-arrowdown);
  background: var(--color-clear);
}
.active-page {
  background: var(--color-primary);
  color: var(--color-clear);
}
.active-page:hover {
  background: var(--color-primary-e8) !important;
  color: var(--color-clear) !important;
  cursor: pointer;
}
/* Component Data Table */
.table {
  background: var(--color-primary-light);
}
.table-header {
  background: var(--color-primary-light);
  border-bottom: 1px solid var(--color-gray-999999);
}
.container-fix-col {
  overflow: auto;
}
.container-fix-col td:first-child {
  background-color: var(--color-clear);
  filter: var(--filter-shadow-fixColumn);
}
.container-fix-col th:first-child {
  background: var(--color-primary-light);
  filter: var(--filter-shadow-fixColumn);
}
.shadow-table {
  filter: var(--filter-shadow);
}

.item-link a {
  color: var(--color-gray-666666) !important;
}
.item-link a:hover {
  color: var(--color-primary) !important;
}
.table-list td {
  color: var(--color-gray-666666) !important;
  font-family: var(--font-family-content) !important;
  border-bottom: 1px solid var(--color-gray-999999) !important;
}
.table-list tr {
  background-color: var(--color-clear);
}
.table-list :hover,
.table-list :hover td:first-child {
  background-color: var(--color-primary-light);
}

.label-footer {
  font-family: var(--font-family-primary);
}

.pagination li:hover {
  background: var(--color-clear-08);
  color: var(--color-black);
}
.icon-active {
  fill: var(--color-primary) !important;
}
.icon-deactive {
  fill: var(--color-gray-999999) !important;
}
.th {
  font-family: var(--font-family-content);
  color: var(--color-gray-222222);
}
tfoot td:first-child {
  background: var(--color-primary-light) !important;
}

/* Component dropdown profile */
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 12px;
  font-size: 1rem;
  color: var(--color-dropdown);
  text-align: left;
  list-style: none;
  background-color: var(--color-clear);
  background-clip: padding-box;
  border: 0;
  border-radius: 0.25rem;
  width: 190px;
  width: 216px;
  margin: 2px 12px !important;
}
/* Component Switch */
.value-toggle {
  font-family: var(--font-family-content);
  color: var(--color-gray-818181);
}
.value-toggle-active {
  font-family: var(--font-family-content);
  color: var(--color-gray-424242);
}

.slider {
  background-color: var(--color-gray-818181);
}
@media (max-width: 1350px) {
  .slider {
    margin-top: 0px !important;
  }
}
.slider:before {
  background-color: var(--color-clear);
  box-shadow: 0px 2px 2px var(--color-black-02);
}

input:checked + .slider {
  background-color: var(--color-primary);
}

input:disabled + .slider {
  background-color: var(--color-gray-999999);
}
/*breadcrumb*/
.breadcrumb {
  font-family: var(--font-family-primary);
}
.breadcrumb li a {
  color: var(--color-gray-424242);
}
/*Component Paragraph*/
.primer {
  color: var(--color-primary);
}
.secondary {
  color: var(--color-gray-999999);
}
.success {
  color: var(--color-success);
}
.warning {
  color: var(--color-warning);
}
.danger {
  color: var(--color-danger);
}
.info {
  color: var(--color-info);
}
.light {
  color: var(--color-gray-f8f9fa);
}
.default {
  color: var(--color-gray-1a1a1a);
}
.dark {
  color: var(--color-gray-212529);
}
.black {
  color: var(--color-black);
}
.white {
  color: var(--color-clear);
}
/*Component Input*/
.invalid {
  background: var(--color-clear);
  border: 1px solid var(--color-danger);
}
.valid {
  background: var(--color-clear);
  border: 1px solid var(--color-success);
}
.valid:focus {
  border: 1px solid var(--color-success);
}
.invalid:focus {
  border: 1px solid var(--color-danger);
}
.input:focus {
  border: 1px solid var(--color-primary);
}
.input {
  background: var(--color-clear);
  border: 1px solid var(--color-gray-cacccf);
}

.disabled {
  background: var(--color-gray-eeeeee);
  color: var(--color-gray-0144);
}
/*Component label*/
.label {
  font-family: var(--font-family-content);
}
/*Component Field*/
.error {
  color: var(--color-danger);
}
.success {
  color: var(--color-success);
}
.standart {
  color: var(--color-gray-818181);
}
.Message {
  font-family: var(--font-family-content);
}

/*Notification*/
.notification-item {
  background: var(--color-clear);
}

.notification-item.success {
  color: var(--color-gray-212529);
  box-shadow: 0px 0px 12px 4px var(--border-success);
}

.notification-item.error {
  color: var(--color-gray-212529);
  box-shadow: 0px 0px 12px 4px var(--border-danger);
}

.notification-item.warning {
  color: var(--color-gray-212529);
  box-shadow: 0px 0px 12px 4px var(--border-warning);
}

.notification-item.info {
  color: var(--color-gray-212529);
  box-shadow: 0px 0px 12px 4px var(--border-info);
}
/*Sidebar Icon*/
.tooltip-text {
  background-color: var(--color-primary-025);
  color: var(--color-primary);
}
/*Switch*/
.switch {
  font-family: var(--font-family-content);
}
.switch-no-label {
  font-family: var(--font-family-content);
}

/*Layout*/
.content {
  background: var(--color-clear);
}
/*Dropdown Profil*/
.dropdown-menu {
  color: var(--color-gray-212529);
  background-color: var(--color-clear);
  border: 1px solid var(--color-clear);
}
.dropdown-item {
  color: var(--color-gray-212529);
  background-color: transparent;
}
.item {
  font-family: var(--font-family-primary);
}
.dropdown-item-logout {
  border-top: 1px solid var(--color-gray-eeeeee);
}
.dropdown-button:hover,
.dropdown-item:hover,
.dropdown-item-logout:hover {
  background: var(--color-primary-025);
  cursor: pointer;
  border-radius: 42px;
  color: var(--color-gray-1d11d);
}
/*Check Box*/
.checkbox input[type="checkbox"]::before,
.checkbox input[type="checkbox"]::after {
  border: 1px solid var(--color-gray-cacccf);
}
.checkbox input[type="checkbox"]:checked::after {
  border: 1px solid var(--color-primary);
  content: "✓";
  color: var(--color-clear);
  background: var(--color-primary);
}
.checkbox input[type="checkbox"]:hover::before,
.checkbox input[type="checkbox"]:focus::before {
  border: 1px solid var(--color-primary);
}
.checkbox input[type="checkbox"]:disabled::before,
.checkbox input[type="checkbox"]:disabled::before {
  border: 1px solid var(--color-gray-ACACAC);
}
.disabled-checkbox input {
  background: var(--color-gray-eeeeee) !important;
  /* border: 1px solid var(--color-gray-ACACAC) !important; */
}
.checkbox input[type="checkbox"]:checked:disabled::after {
  border: 1px solid var(--color-gray-ACACAC);
  content: "✓";
  color: var(--color-clear);
  background: var(--color-gray-ACACAC);
}
/**/
.side-icon {
  background: var(--color-primary);
}
.item-icon-active,
.item-icon:hover {
  background: var(--color-clear);
}
.item-icon-active svg path,
.item-icon:hover svg path {
  fill: var(--color-primary);
}
/*sidebar*/
.side-menu {
  background: var(--color-clear);
  box-shadow: 0px 2px 10px var(--color-black-02);
}
.side-icon-collapse {
  background: var(--color-primary);
}
.side-bar {
  background: var(--color-clear);
}
/*SideBarMenu*/
.side-bar-menu:hover {
  background: var(--color-clear) !important;
}
.side-bar-men a li {
  color: var(--color-gray-666666);
}
.side-title {
  color: var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
}
.sub-menu-title {
  color: var(--color-gray-666666);
}
.icon-sub-menu svg path,
.icon-accordion svg path {
  fill: var(--color-gray-424242);
}
.sub-sub-menu {
  color: var(--color-gray-666666);
  background: var(--color-primary-025);
}
.sub-sub-menu a {
  color: var(--color-gray-666666);
}
.sub-menu-active {
  background: var(--color-primary-025);
}
.sub-menu-active .sub-menu-title {
  color: var(--color-primary);
}

.sub-menu-active svg path {
  fill: var(--color-primary);
}
.sub-menu:hover .sub-menu-title {
  color: var(--color-primary);
}

.sub-menu:hover svg path {
  fill: var(--color-primary);
}
.side-bar-menu {
  color: var(--color-gray-666666);
}

/* Addtional */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-gray-c4c4c4);
}
div {
  scrollbar-color: var(--color-primary) var(--color-gray-c4c4c4);
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 8px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::placeholder {
  color: var(--color-gray-03);
}
input::-webkit-calendar-picker-indicator {
  display: none;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none !important;
  -webkit-appearance: none !important;
  visibility: hidden !important;
  margin: 0;
  opacity: 0;
}
