.Col{
  padding: 0px !important;
}
.Row{
  margin-bottom: 18px;
}
.Properties{
  width: 100px;
}
.Box{
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 16px;
  word-wrap: break-word;
}