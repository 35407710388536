
.IconSearch svg {
  margin-right: 8px;
  height: 12px;
  width: 12px;
}
.IconSearch {
  height: 10%;
}
.BoxInputSearch {
  display: flex;
  margin: 0 8px;
  margin-top: 8px;
  align-items: center;
  border-radius: 8px;
  background-color: #f5ebf3;
}
.InputSearch {
  width: 100%;
  border: none;
  border-radius: 8px;
  background-color: #f5ebf3;
  padding: 8px;
}
.InputSearch:focus {
  border: none;
}
.SlcInput {
  display: flex;
  align-items: center;
  align-content: center;
  background: #ffffff;
  border: 1px solid #cacccf;
  border-radius: 6px;
  width: 100%;
  height: 33px;
  padding: 8px 12px;
}
.SlcInputWithChip {
  display: flex;
  align-content: center;
  background: #ffffff;
  border: 1px solid #cacccf;
  border-radius: 6px;
  width: 100%;
  max-height: 66px;
  padding: 0px 0px 0px 12px;
  max-height: 66px;
  overflow-y: auto;
  margin: 0;
    padding: 0;
    overflow: auto;
    max-height: 60px;
}
.SlcInputsWithChip{
  width: calc(100% - 10px);
  /* overflow: auto; */

  display: flex;
  align-items: center;
 
}
.SlcInput {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: rgba(66, 66, 66, 0.3);
}
.SlcInput svg {
  position: absolute;
  right: 0;
  /* float: inline-end; */
  /* width: 10px; */
}
.ArrowIcon{
  /* display: flex;
  flex-flow: column wrap;
  align-content: flex-end; */
  /* float: right;
  position: absolute; */
  margin-right: 12px;
  /* right: 0; */
}
/* .SlcInput Span {
  width: calc(100% - 10px);
} */
.Inputwrp[data-placeholder]:not([data-placeholder=""]):empty::before {

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(66, 66, 66, 0.3);
  content: attr(data-placeholder);
}
.SlcInputs[data-placeholder]:not([data-placeholder=""]):empty::before {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(66, 66, 66, 0.3);
  content: attr(data-placeholder);
}
.SlcInputs {
  width: calc(100% - 10px);
  padding: 3px 12px;
}
.SlcOption {
  margin: 8px 0;
  position: absolute;
  background: #ffffff;
  border: 1px solid #cacccf;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: block;
  /* overflow-y:auto ; ss */
  max-height: 200px;
}
.SlcOptionDown {
  overflow-y: auto;
  z-index: 9999;
  margin-top: 10px;
  clip-path: inset(0px -10px -10px -10px);
  transition: border-bottom-left-radius 0.5s ease-in, border-bottom-right-radius 0.5s ease-in;
}
.SlcOptionUp {
  z-index: 9999;
  bottom: 60px;
  clip-path: inset(-10px -10px 0 -10px);
  transition: border-top-left-radius 700ms, border-top-right-radius 700ms;
}
.SlcOptionUl {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 200px;
}
.SlcOptionUlSearch{
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  max-height: 150px;
}
.SlcOptionUlSearch::-webkit-scrollbar-track,.SlcOptionUl::-webkit-scrollbar-track {
  margin:5px 0;
 
}
.GruopChip{
  /* width: 100%; */
}
.Chip{
  background: #9B3789;
border: 1px solid #9B3789;
border-radius: 10px;
color: white;
  margin: 5px;
  padding: 1px 8px;
  white-space: nowrap;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  line-break: anywhere;
  white-space: break-spaces;
}
.Chip svg{
  margin-left: 5px;
}
.SlcOptionLi {
  word-wrap: break-word;
  display: flex;
  padding: 0;
  margin: 0;
  padding: 10px 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;

}
.SlcOptionLiSpan{
  width: calc(100% - 20px);
}
.SlcOptionLi:hover {
  border-radius: 6px;
}


.Inputwrp {
  /* margin-top: 5px; */
  width: calc(100% - 0px);
  /* display: inline-block; */
  vertical-align: top;
  /* height: 32px;
  max-height: 66px;
  overflow-y: auto; */
}
.Inputwrp::-webkit-scrollbar-track {
  margin:3px 0;
  margin-left: -12px;
 
}