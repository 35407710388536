.ButtonGroup {
  /* position: absolute; */
  right: 24px;
}
.ButtonRight {
  margin-left: 24px;
}
.ButtonRight svg{
margin-right: 6px;
}
/* .ButtonLeft {
  background: rgba(255, 255, 255, 0);
  color: #9b3789;
  border: 2px solid #9b3789;
}
.ButtonRight {
  background: #9b3789;
  color: white;
  border: 2px solid #9b3789;
}
.ButtonRight:hover,
.ButtonLeft:hover {
  background: #9b3789b9;
  color: white;
  border: none;
}

.ButtonGroup button {
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 8px;
  width: 124px;
  height: 40px;
  font-weight: 500;
} */
